<template>
  <div class="page-header">
    <slot />
  </div>
</template>

<style lang="scss">
.page-header {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  height: 60px;
  border-bottom: 1px solid var(--border-default);
  padding: 10px 40px;

  h1 {
    margin-bottom: 0 !important;
  }
}
</style>
