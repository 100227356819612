import { type Directive } from 'vue'
import scrollTo from 'vue-scrollto'

export const vFocus = {
  mounted: (element, binding) => {
    const { value = true } = binding

    if (!value) {
      return
    }

    const targetElement = element.querySelector<HTMLElement>(
      ':is(.multiselect [tabindex="0"], input, textarea):not([disabled], .is-disabled)'
    )

    if (!targetElement) {
      return
    }

    let delay = 300

    if (binding.modifiers.slow) {
      delay = 400
    }

    setTimeout(() => {
      if (document.querySelector(':is(.multiselect [tabindex="0"], input, textarea):focus')) {
        return
      }

      targetElement?.focus()

      if (targetElement.closest('.modal')) {
        return
      }

      scrollTo.scrollTo(targetElement, { offset: -110, easing: 'ease', duration: 1000 })
    }, delay)
  },
} satisfies Directive<HTMLElement, boolean | undefined>
