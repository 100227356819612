import { definePlugin } from '@src/bootstrap/app'

export default definePlugin(({ pinia }) => {
  pinia.use(({ store }) => {
    store.$destroy = () => {
      delete pinia.state.value[store.$id]
      store.$dispose()
    }
  })
})
