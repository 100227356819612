import hash from 'object-hash'

export function useWaitFn<P extends any[], T>(fn: (...args: P) => Promise<T>) {
  const promiseMap: Record<string, Promise<T>> = {}

  return async (...args: P) => {
    const key = hash(args)

    promiseMap[key] ??= fn(...args).finally(() => delete promiseMap[key])

    return promiseMap[key]
  }
}
