import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { RouteName } from '@src/types/router.type'

export const creationToolRoute = {
  name: RouteName.CreationTool,
  path: '/creation-tool',
  component: () => import('../views/creationTool/CreationTool.vue'),
  meta: {
    canView: ({ permissions }) => {
      return [...permissions].some((permission) => permission.startsWith('app.client.view_advertiser_acts'))
    },
  },
} satisfies DeepReadonly<RouteRecordRaw>
