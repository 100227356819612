import { LS_STATE } from '@src/modules/oauth/constants/common.constant'

import { type Storage } from '@src/modules/oauth/types/common.type'

export const LocalStorage: Storage = {
  saveState: (serializedState) => localStorage.setItem(LS_STATE, serializedState),
  loadState: () => localStorage.getItem(LS_STATE),
}

export { LocalStorage as DefaultStorage }
