import { definePlugin } from '@src/bootstrap/app'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

export default definePlugin(({ router }) => {
  NProgress.configure({ showSpinner: false })

  router.beforeEach((to, from) => {
    if (to.name !== from.name) {
      NProgress.start()
    }
  })

  router.afterEach(() => {
    NProgress.done()
  })
})
