import * as _ from 'lodash-es'

import { type Maybe } from '@src/types/common.type'

export function formatCurrency(value?: Maybe<number>, withZero = true, currencySing = '₽') {
  const totalValue = withZero ? value || 0 : value ? value : null

  return _.isNumber(totalValue) ? `${formatNumber(totalValue)} ${currencySing}` : ''
}

export function enumToArray<T extends Record<string, string | number>>(object: T) {
  return Object.entries(object)
    .filter((entry): entry is [string, T[keyof T]] => isNaN(Number(entry[0])))
    .map(([, value]) => value)
}

export function sleep(time = 1000) {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, time)
  })
}

export function formatNumber(num: Maybe<number>) {
  return _.isNumber(num) ? num.toLocaleString('ru-RU').replace(',', '.') : ''
}

export const isEmpty = (value: any) => {
  if (_.isBoolean(value)) {
    return !value
  }

  if (_.isNumber(value)) {
    return false
  }

  if (_.isString(value)) {
    return value.length === 0
  }

  return _.isNil(value) || _.isEmpty(value)
}

export const isLink = (value: string) => {
  try {
    new URL(value)

    // new URL не проверяет наличие домена 2-го уровня и корректность протокола.
    return /(ht|s?f)tps?:\/\/.+[\dA-Za-zА-я]\.[\dA-Za-zА-я]/.test(value)
  } catch (e) {
    return false
  }
}
