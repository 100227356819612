import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { RouteName } from '@src/types/router.type'

export const monthlyActsRoute = {
  name: RouteName.MonthlyActs,
  path: '/monthly-acts',
  component: () => import('@src/views/monthlyActs/MonthlyActs.vue'),
  redirect: { name: RouteName.MonthlyActList },
  children: [
    {
      name: RouteName.MonthlyActList,
      path: '',
      component: () => import('@src/views/monthlyActs/views/list/MonthlyActList.vue'),
    },
  ],
} satisfies DeepReadonly<RouteRecordRaw>
