import { createGlobalState } from '@vueuse/core'
import axios from 'axios'

import { useApi } from '@src/hooks/useApi.hook'
import { useOauth } from '@src/hooks/useOauth.hook'

import { type BaseResponse } from '@src/types/api/common.type'
import {
  type ConnectionToken,
  type SubscriptionTokenResponse,
  type WebSocketChannelName,
} from '@src/types/api/webSocket.type'

export const useSocketApi = createGlobalState(() => {
  const url = '/v1/websocket'

  const api = useApi()

  function fetchConnectionToken() {
    return api.get<any, BaseResponse<ConnectionToken>>(`${url}/connection-token`)
  }

  async function fetchSubscriptionToken(channel: WebSocketChannelName) {
    const oauth = useOauth()

    const { accessToken } = await oauth.getTokens()

    return axios.get<any, BaseResponse<SubscriptionTokenResponse>>(import.meta.env.VITE_BROADCASTING_AUTH_URL, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { channel },
    })
  }

  return { fetchConnectionToken, fetchSubscriptionToken }
})
