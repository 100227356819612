// eslint-disable-next-line no-restricted-imports
import { useVModel as _useVModel } from '@vueuse/core'
import * as _ from 'lodash-es'

import { type KebabCase } from '@src/types/common.type'

export function useVModel<P extends object, K extends Extract<keyof P, string>, V extends P[K]>(
  props: P,
  key: K,
  emit: (name: `update:${KebabCase<K>}`, value: V) => void
) {
  return _useVModel(props, key, emit, {
    eventName: `update:${_.kebabCase(key)}`,
    clone: _.cloneDeep,
    deep: true,
    passive: true,
  })
}
