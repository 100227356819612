import { createGlobalState, tryOnUnmounted } from '@vueuse/core'
import * as _ from 'lodash-es'
import NProgress from 'nprogress'
import { ref, watch } from 'vue'

// Локальное хранилище, не должно шариться глобально.
const useProgressState = createGlobalState(() => {
  const state = ref(new Map<string, true>())

  watch(
    () => !!state.value.size,
    (size) => {
      if (size) {
        NProgress.start()
      } else {
        NProgress.done()
      }
    }
  )

  return state
})

export function useProgress() {
  const key = _.uniqueId('progress-')

  const state = useProgressState()

  const loading = ref(false)

  watch(loading, (loading) => {
    if (loading) {
      state.value.set(key, true)
    } else {
      state.value.delete(key)
    }
  })

  tryOnUnmounted(() => state.value.delete(key))

  return loading
}
