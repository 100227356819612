import { definePlugin } from '@src/bootstrap/app'

export default definePlugin(({ head }) => {
  head.push({
    titleTemplate: (title) => {
      const titleHead = title ? `${title} - ` : ''

      return `${titleHead}Cerebro`
    },
  })
})
