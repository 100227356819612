import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { RouteName } from '@src/types/router.type'

export const documentsRoute = {
  name: RouteName.Documents,
  path: '/documents',
  component: () => import('@src/views/documents/Documents.vue'),
  redirect: { name: RouteName.DocumentList },
  children: [
    {
      name: RouteName.DocumentList,
      path: '',
      component: () => import('@src/views/documents/views/list/DocumentList.vue'),
    },
  ],
} satisfies DeepReadonly<RouteRecordRaw>
