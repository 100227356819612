import { createGlobalState } from '@vueuse/core'

import { useApi } from '@src/hooks/useApi.hook'

import { type BaseResponse } from '@src/types/api/common.type'
import { type Settings } from '@src/types/api/settings.type'

export const useSettingsApi = createGlobalState(() => {
  const url = '/v1/settings'

  const api = useApi()

  function fetchSettings() {
    return api.get<any, BaseResponse<Settings>>(url)
  }

  return { fetchSettings }
})
