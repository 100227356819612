import { definePlugin } from '@src/bootstrap/app'
import { createApp } from 'vue'
import { directive as vTippy } from 'vue-tippy'

import VueroApp from '@src/VueroApp.vue'

import { vFocus } from '@src/bootstrap/directives/focus.directive'

export default definePlugin(({ app }) => {
  // Для того чтобы IDE индексировала подключенные директивы, необходимо вызвать createApp в этом же контексте.
  // eslint-disable-next-line no-constant-condition
  if (0) {
    createApp(VueroApp)
  }

  app.directive('focus', vFocus)
  app.directive('tippy', vTippy)
})
