import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { RouteName } from '@src/types/router.type'

export const profileRoute = {
  name: RouteName.Profile,
  path: '/profile',
  component: () => import('@src/views/profile/Profile.vue'),
  redirect: { name: RouteName.ProfileEdit },
  children: [
    {
      name: RouteName.ProfileEdit,
      path: '',
      component: () => import('@src/views/profile/views/edit/ProfileEdit.vue'),
    },
    {
      name: RouteName.ProfileExchangePassword,
      path: 'exchange-password',
      component: () => import('@src/views/profile/views/exchangePassword/ProfileExchangePassword.vue'),
    },
    {
      name: RouteName.ProfileNotifications,
      path: 'notifications',
      component: () => import('@src/views/profile/views/notifications/ProfileNotifications.vue'),
    },
  ],
} satisfies DeepReadonly<RouteRecordRaw>
