import { createRouter as createClientRouter, createWebHistory } from 'vue-router'

import { commonRoutes } from '@src/routes/common.route'

export function createRouter() {
  return createClientRouter({
    history: createWebHistory(),
    routes: commonRoutes,
  })
}
