import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { creationToolRoute } from './creationTool.route'
import { advertiserActsRoute } from '@src/routes/advertiserActs.route'
import { advertisersRoute } from '@src/routes/advertisers.route'
import { authRoute } from '@src/routes/auth.route'
import { bonusesRoute } from '@src/routes/bonuses.route'
import { cabinetsRoute } from '@src/routes/cabinets.route'
import { clientsRoute } from '@src/routes/clients.route'
import { documentsRoute } from '@src/routes/documents.route'
import { impersonateRoute } from '@src/routes/impersonate.route'
import { invoicesRoute } from '@src/routes/invoices.route'
import { monthlyActsRoute } from '@src/routes/monthlyActs.route'
import { pointsRoute } from '@src/routes/points.route'
import { profileRoute } from '@src/routes/profile.route'
import { tasksRoute } from '@src/routes/tasks.route'
import { transactionsRoute } from '@src/routes/transactions.route'

import { RouteName } from '@src/types/router.type'

export const commonRoutes = [
  {
    name: RouteName.Root,
    path: '/',
    component: () => import('@src/layouts/SideblockLayout.vue'),
    redirect: { name: RouteName.Clients },
    children: [
      advertiserActsRoute,
      advertisersRoute,
      authRoute,
      impersonateRoute,
      cabinetsRoute,
      bonusesRoute,
      clientsRoute,
      invoicesRoute,
      documentsRoute,
      monthlyActsRoute,
      pointsRoute,
      profileRoute,
      tasksRoute,
      transactionsRoute,
      creationToolRoute,
    ],
  },
  {
    name: RouteName.NotFound,
    path: '/:path(.*)*',
    component: () => import('@src/views/notFound/NotFound.vue'),
  },
] satisfies DeepReadonly<RouteRecordRaw[]>
