import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { RouteName } from '@src/types/router.type'

export const advertiserActsRoute = {
  name: RouteName.AdvertiserActs,
  path: '/advertiser-acts',
  component: () => import('@src/views/advertiserActs/AdvertiserActs.vue'),
  redirect: { name: RouteName.AdvertiserActList },
  meta: {
    canView: ({ permissions }) => {
      return [...permissions].some((permission) => permission.startsWith('app.client.view_advertiser_acts'))
    },
  },
  children: [
    {
      name: RouteName.AdvertiserActList,
      path: '',
      component: () => import('@src/views/advertiserActs/views/list/AdvertiserActList.vue'),
    },
    {
      name: RouteName.AdvertiserActCard,
      path: ':advertiserActId(\\d+)',
      component: () => import('@src/views/advertiserActs/views/card/AdvertiserActCard.vue'),
    },

    {
      name: RouteName.AdvertiserActCreate,
      path: 'create',
      component: () => import('@src/views/advertiserActs/views/save/AdvertiserActSave.vue'),
    },

    {
      name: RouteName.AdvertiserActUpdate,
      path: ':advertiserActId(\\d+)/update',
      component: () => import('@src/views/advertiserActs/views/save/AdvertiserActSave.vue'),
    },
  ],
} satisfies DeepReadonly<RouteRecordRaw>
