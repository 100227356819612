import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { RouteName } from '@src/types/router.type'

export const tasksRoute = {
  name: RouteName.Tasks,
  path: '/tasks',
  component: () => import('@src/views/tasks/Tasks.vue'),
  redirect: { name: RouteName.TaskList },
  children: [
    {
      name: RouteName.TaskList,
      path: '',
      component: () => import('@src/views/tasks/views/list/TaskList.vue'),
    },
  ],
} satisfies DeepReadonly<RouteRecordRaw>
