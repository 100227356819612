import { storeToRefs } from 'pinia'
import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { useSettingsStore } from '@src/stores/settings.store'

import { RouteName } from '@src/types/router.type'

export const clientsRoute = {
  name: RouteName.Clients,
  path: '/clients',
  component: () => import('@src/views/clients/Clients.vue'),
  redirect: { name: RouteName.ClientList },
  children: [
    {
      name: RouteName.ClientList,
      path: '',
      component: () => import('@src/views/clients/views/list/ClientList.vue'),
    },
    {
      name: RouteName.ClientView,
      path: ':clientId(\\d+)',
      component: () => import('@src/views/clients/views/view/ClientView.vue'),
    },
    {
      name: RouteName.ClientBonusesAllocate,
      path: ':clientId(\\d+)/bonuses-allocate',
      component: () => import('@src/views/clients/views/bonusesAllocate/ClientBonusesAllocate.vue'),
      meta: { canView: ({ permissions }) => permissions.has('app.bonuses.allocate') },
    },
    {
      name: RouteName.ClientCreate,
      path: 'create',
      component: () => {
        const { settings } = storeToRefs(useSettingsStore())

        if (settings.value?.attributes.enableFrontendWizard) {
          return import('@src/views/clients/views/create/ClientCreate.vue')
        }

        return import('@src/views/clients/views/save/ClientSave.vue')
      },
    },
    {
      name: RouteName.ClientCreateDefault,
      path: 'create-default',
      component: () => import('@src/views/clients/views/save/ClientSave.vue'),
      props: { initAgency: false },
    },
    {
      name: RouteName.ClientCreateAgency,
      path: 'create-agency',
      component: () => import('@src/views/clients/views/save/ClientSave.vue'),
      props: { initAgency: true },
    },

    {
      name: RouteName.ClientUpdate,
      path: ':clientId(\\d+)/update',
      component: () => import('@src/views/clients/views/save/ClientSave.vue'),
    },
  ],
} satisfies DeepReadonly<RouteRecordRaw>
