import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { RouteName } from '@src/types/router.type'

export const invoicesRoute = {
  name: RouteName.Invoices,
  path: '/invoices',
  component: () => import('@src/views/invoices/Invoices.vue'),
  redirect: { name: RouteName.InvoiceList },
  children: [
    {
      name: RouteName.InvoiceList,
      path: '',
      component: () => import('@src/views/invoices/views/list/InvoiceList.vue'),
    },
    {
      name: RouteName.InvoiceCard,
      path: ':invoiceId(\\d+)',
      component: () => import('@src/views/invoices/views/card/InvoiceCard.vue'),
    },
    {
      name: RouteName.InvoiceCreate,
      path: 'create',
      component: () => import('@src/views/invoices/views/create/InvoiceCreate.vue'),
    },
  ],
} satisfies DeepReadonly<RouteRecordRaw>
