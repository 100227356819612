import { definePlugin } from '@src/bootstrap/app'
import { type Config, initYandexMetrika } from 'yandex-metrika-vue3'

export default definePlugin(({ app, router }) => {
  if (!import.meta.env.VITE_YANDEX_METRIKA_ENABLED || !import.meta.env.VITE_YANDEX_METRIKA_ID) {
    return
  }

  const metrikaConfig = <Config>{
    id: import.meta.env.VITE_YANDEX_METRIKA_ID,
    env: import.meta.env.VITE_YANDEX_METRIKA_ENV || 'development',
    debug: import.meta.env.VITE_YANDEX_METRIKA_ENV === 'development',
    options: {
      clickmap: Boolean(import.meta.env.VITE_YANDEX_METRIKA_OPTION_CLICKMAP),
      trackLinks: Boolean(import.meta.env.VITE_YANDEX_METRIKA_OPTION_TRACK_LINKS),
      accurateTrackBounce: Boolean(import.meta.env.VITE_YANDEX_METRIKA_OPTION_ACCURATE_TRACK_BOUNCE),
      webvisor: Boolean(import.meta.env.VITE_YANDEX_METRIKA_OPTION_WEBVISOR),
    },
    router,
  }

  app.use(initYandexMetrika, metrikaConfig)
})
