import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { useOauth } from '@src/hooks/useOauth.hook'

import { RouteName } from '@src/types/router.type'

export const impersonateRoute = {
  name: RouteName.Impersonate,
  path: '/impersonate',
  component: () => import('@src/views/loading/Loading.vue'),
  beforeEnter: async (route) => {
    const oauth = useOauth()

    const token = (route.query.token as string) || null

    if (!token) {
      console.warn('IMPERSONATE ROUTE ERROR: not token')

      return
    }

    try {
      await oauth.setImpersonateToken(token)
      await oauth.return()
    } catch (e) {
      console.warn('AUTH ROUTE ERROR', e)
    }
  },
} satisfies DeepReadonly<RouteRecordRaw>
