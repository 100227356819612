import { createGlobalState } from '@vueuse/core'
import { type Component, ref } from 'vue'

interface Popup {
  component: Component
  props: Record<string, unknown>
}

export const usePopups = createGlobalState(() => {
  const popups = ref<Record<string, Popup>>({})

  function open(key: string, popup: Popup) {
    popups.value[key] = popup
  }

  function close(key: string) {
    setTimeout(() => delete popups.value[key], 0)
  }

  function closeAll() {
    setTimeout(() => (popups.value = {}), 0)
  }

  return { popups, open, close, closeAll }
})
