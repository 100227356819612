<script lang="ts">
import { computed, defineComponent, h, type PropType, type VNode } from 'vue'
import { type RouteLocationRaw, RouterLink } from 'vue-router'

import VIcon from '@src/components/base/icon/VIcon.vue'
import VPlaceload from '@src/components/base/loader/VPlaceload.vue'

import { type BulmaColor } from '@src/types/bulma.type'
import { type Maybe } from '@src/types/common.type'

export default defineComponent({
  props: {
    color: { type: <PropType<BulmaColor>>String, default: undefined },
    label: { type: String, default: undefined },
    to: { type: <PropType<RouteLocationRaw>>[Object, String], default: undefined },
    href: { type: <PropType<Maybe<string>>>String, default: undefined },
    target: { type: String, default: undefined },
    icon: { type: String, default: undefined },
    iconCaret: { type: String, default: undefined },
    placeload: { type: String, default: undefined },
    rounded: { type: Boolean, default: false },
    fullwidth: { type: Boolean, default: false },
    light: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    static: { type: Boolean, default: false },
  },

  setup(props, { slots, attrs }) {
    const classes = computed(() => {
      return [
        'button',
        props.color && `is-${props.color}`,
        props.disabled && 'is-disabled',
        props.rounded && 'is-rounded',
        props.fullwidth && 'is-fullwidth',
        props.outlined && 'is-outlined',
        props.loading && !props.placeload && 'is-loading',
        props.light && 'is-light',
        props.static && 'is-static',
      ]
    })

    const genChildren = () => {
      const children: VNode[] = []

      if (props.icon) {
        children.push(h(VIcon, { icon: props.icon }))
      }

      if (props.placeload && props.loading) {
        children.push(h(VPlaceload, { width: props.placeload }))
      } else if (props.label || slots.default) {
        children.push(h('span', props.label || slots.default?.()))
      }

      if (props.iconCaret && !props.disabled) {
        children.push(h(VIcon, { icon: props.iconCaret }))
      }

      return children
    }

    return () => {
      if (props.to) {
        return h(RouterLink, { ...attrs, to: props.to, class: classes.value }, genChildren)
      }

      if (props.href) {
        return h('a', { ...attrs, href: props.href, target: props.target, class: classes.value }, genChildren())
      }

      return h('button', { type: 'button', ...attrs, disabled: props.disabled, class: classes.value }, genChildren())
    }
  },
})
</script>
