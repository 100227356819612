import { createGlobalState } from '@vueuse/core'

import { useApi } from '@src/hooks/useApi.hook'

import { type BaseResponse } from '@src/types/api/common.type'
import { type UpdateUser, type User, type UserExchangePassword, type UserPermission } from '@src/types/api/user.type'

export const useUserApi = createGlobalState(() => {
  const url = '/v1/user'

  const api = useApi()

  function fetchUser() {
    return api.get<any, BaseResponse<User>>(url)
  }

  function updateUser(data: UpdateUser) {
    return api.patch<any, BaseResponse<User>>(url, data)
  }

  function updateAvatar(file: Blob) {
    const formData = new FormData()

    formData.append('avatar', file)

    return api.post<any, BaseResponse<User>>(`${url}/avatar`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  function fetchPermissions() {
    return api.get<any, BaseResponse<UserPermission[]>>(`${url}/permissions`)
  }

  function exchangePassword(data: UserExchangePassword) {
    return api.patch<any, void>(`${url}/exchange-password`, data)
  }

  function sendEmailVerification() {
    return api.post<any, void>(`${url}/send-email-verification`)
  }

  return { fetchUser, updateUser, fetchPermissions, exchangePassword, sendEmailVerification, updateAvatar }
})
