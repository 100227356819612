import CryptoJS from 'crypto-js'
import * as _ from 'lodash-es'

import { PKCE_CHARSET, RECOMMENDED_CODE_VERIFIER_LENGTH } from '@src/modules/oauth/constants/common.constant'

export function parseWwwAuthenticateHeader(header: string) {
  return Object.fromEntries(
    header
      .slice('Bearer '.length)
      .replace(/"/g, '')
      .split(',')
      .map((pair) => {
        const [key, value] = pair.trim().split('=')

        return [_.camelCase(key), value]
      })
  )
}

function base64UrlEncode(value: string) {
  let base64 = btoa(value)

  base64 = base64.replace(/\+/g, '-')
  base64 = base64.replace(/\//g, '_')
  base64 = base64.replace(/=/g, '')

  return base64
}

export function extractParamFromUrl(param: string, url: string) {
  let queryString = url.split('?')

  if (queryString.length < 2) {
    return
  }

  // remove URL fragments that SPAs usually use
  queryString = queryString[1].split('#')

  const parts = queryString[0].split('&')

  for (const part of parts) {
    const [key, value] = part.split('=')

    if (key === param) {
      return decodeURIComponent(value)
    }
  }
}

export function wordArrayToU8Array(wordArray: CryptoJS.lib.WordArray) {
  const { words, sigBytes } = wordArray

  const u8 = new Uint8Array(sigBytes)

  for (let i = 0; i < sigBytes; i++) {
    u8[i] = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff
  }

  return u8
}

export async function generateCodeChallengeAndVerifier() {
  const output = new Uint32Array(RECOMMENDED_CODE_VERIFIER_LENGTH)

  crypto.getRandomValues(output)

  const codeVerifier = base64UrlEncode(
    Array.from(output)
      .map((num) => PKCE_CHARSET[num % PKCE_CHARSET.length])
      .join('')
  )

  const hash = wordArrayToU8Array(CryptoJS.SHA256(codeVerifier))

  let binary = ''

  const hashLength = hash.byteLength

  for (let i = 0; i < hashLength; i++) {
    binary += String.fromCharCode(hash[i])
  }

  const codeChallenge = base64UrlEncode(binary)

  return { codeChallenge, codeVerifier }
}

export function generateRandomState(lengthOfState: number) {
  const output = new Uint32Array(lengthOfState)

  crypto.getRandomValues(output)

  return Array.from(output)
    .map((num) => PKCE_CHARSET[num % PKCE_CHARSET.length])
    .join('')
}
