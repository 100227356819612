<template>
  <div class="minimal-layout">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style lang="scss">
.minimal-layout {
  position: relative;
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
}
</style>
