import mitt, { type Emitter } from 'mitt'

import { type EmitterEvent } from '@src/types/event.type'

let emitter: Emitter<EmitterEvent>

export function createEmitter() {
  emitter = mitt()

  return emitter
}

export function useEmitter() {
  if (!emitter) {
    createEmitter()
  }

  return emitter
}
