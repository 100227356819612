import * as Sentry from '@sentry/vue'
import { definePlugin } from '@src/bootstrap/app'
import { watch } from 'vue'

import { useUserStore } from '@src/stores/user.store'

export default definePlugin(({ app, router }) => {
  const userStore = useUserStore()

  Sentry.init({
    app,
    enabled: !!import.meta.env.VITE_SENTRY_DSN,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.captureConsoleIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

  watch(
    () => userStore.user,
    (user) => {
      if (!user) {
        return Sentry.setUser(null)
      }

      Sentry.setUser({
        id: user.id,
        email: user.attributes.email,
        username: user.attributes.name,
      })
    },
    { immediate: true }
  )
})
