<template>
  <span :key="icon" class="icon">
    <i :class="icon" />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    icon: { type: String, required: true },
    size: { type: Number, default: 24 },
  },
})
</script>

<style lang="scss">
.icon {
  font-size: calc(v-bind(size) * 1px);
}
</style>
