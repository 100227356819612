<template>
  <minimal-layout theme="darker">
    <div class="loader-container">
      <div class="loader-wrapper">
        <svg class="ip" height="128px" viewBox="0 0 256 128" width="256px" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="grad1" x1="0" x2="1" y1="0" y2="0">
              <stop offset="0%" stop-color="#5ebd3e" />
              <stop offset="33%" stop-color="#ffb900" />
              <stop offset="67%" stop-color="#f78200" />
              <stop offset="100%" stop-color="#e23838" />
            </linearGradient>

            <linearGradient id="grad2" x1="1" x2="0" y1="0" y2="0">
              <stop offset="0%" stop-color="#e23838" />
              <stop offset="33%" stop-color="#973999" />
              <stop offset="67%" stop-color="#009cdf" />
              <stop offset="100%" stop-color="#5ebd3e" />
            </linearGradient>
          </defs>

          <g fill="none" stroke-linecap="round" stroke-width="16">
            <g class="ip__track" stroke="#ddd">
              <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
              <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
            </g>

            <g stroke-dasharray="180 656">
              <path
                class="ip__worm1"
                d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"
                stroke="url(#grad1)"
                stroke-dashoffset="0"
              />

              <path
                class="ip__worm2"
                d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"
                stroke="url(#grad2)"
                stroke-dashoffset="358"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  </minimal-layout>
</template>

<script lang="ts">
import { useHead } from '@unhead/vue'
import { defineComponent } from 'vue'

import MinimalLayout from '@src/layouts/MinimalLayout.vue'

export default defineComponent({
  components: {
    MinimalLayout,
  },

  setup() {
    useHead({ title: 'Загрузка...' })
  },
})
</script>

<style lang="scss">
.loader-container {
  --hue: 147;
  --bg: hsl(var(--hue) 50% 100%);
  --fg: hsl(var(--hue) 50% 70%);
  --trans-dur: 0.3s;

  background-color: var(--bg);
  color: var(--fg);
  font: 1em/1.5 sans-serif;
  height: 100vh;
  display: grid;
  place-items: center;
  transition: background-color var(--trans-dur);

  .loader-wrapper {
    padding: 1.5em 0;

    .ip {
      width: 16em;
      height: 8em;
    }

    .ip__track {
      stroke: hsl(var(--hue) 90% 90%);
      transition: stroke var(--trans-dur);
    }

    .ip__worm1,
    .ip__worm2 {
      animation: worm1 2s linear infinite;
    }

    .ip__worm2 {
      animation-name: worm2;
    }
  }
}

.is-dark {
  .loader-container {
    --bg: hsl(var(--hue) 50% 5%);
    --fg: hsl(var(--hue) 50% 60%);

    .loader-wrapper {
      .ip__track {
        stroke: hsl(var(--hue) 90% 15%);
      }
    }
  }
}

@keyframes worm1 {
  0% {
    stroke-dashoffset: 0;
  }

  50% {
    animation-timing-function: steps(1);
    stroke-dashoffset: -358;
  }

  50.01% {
    animation-timing-function: linear;
    stroke-dashoffset: 358;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes worm2 {
  0% {
    stroke-dashoffset: 358;
  }

  50% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -358;
  }
}
</style>
