import { type Router, useRouter } from 'vue-router'

import { Oauth } from '@src/modules/oauth/oauth.module'

let oauth: Oauth

export function createOauth(router: Router) {
  oauth = new Oauth({
    scopes: ['*'],
    authorizationUrl: `${import.meta.env.VITE_OAUTH_API_URL}/authorize`,
    tokenUrl: `${import.meta.env.VITE_OAUTH_API_URL}/token`,
    fallbackUrl: `${import.meta.env.VITE_OAUTH_FALLBACK_URL}` ?? '/',
    clientId: import.meta.env.VITE_OAUTH_CLIENT_ID,
    redirectUri: import.meta.env.VITE_OAUTH_REDIRECT_URI,
    originUrlResolver: () => router.currentRoute.value.fullPath,
    storeRefreshToken: true,
    // optional:
    onAccessTokenExpiry() {
      // when the access token has expired
      return this.exchangeRefreshTokenForAccessToken()
    },
    onAccessDenied() {
      console.warn('Access denied. Fallback redirect.')
      location.replace(this.config.fallbackUrl)
    },
    onInvalidGrant() {
      console.warn('Invalid grant error. Check OAuth config.')
    },
    onInvalidToken() {
      // user will be returned to originUrlResolver value, after success authentication
      return this.authorize()
    },
  })

  return oauth
}

export function useOauth() {
  if (!oauth) {
    const router = useRouter()

    createOauth(router)
  }

  return oauth
}
