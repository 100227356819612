<template>
  <component :is="tag" class="placeload">
    <div class="content-shape loads" />
  </component>
</template>

<script lang="ts">
import { useCurrentElement } from '@vueuse/core'
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  props: {
    tag: { type: String, default: 'div' },
    width: { type: String, default: '100%' },
    height: { type: String, default: undefined },
  },

  setup(props) {
    const currentElement = useCurrentElement<HTMLElement>()

    const shapeHeight = ref(props.height)
    const targetHeight = ref(props.height)

    onMounted(() => {
      if (props.height) {
        return
      }

      const style = getComputedStyle(currentElement.value)

      shapeHeight.value = style.fontSize
      targetHeight.value = style.lineHeight
    })

    return {
      shapeHeight,
      targetHeight,
    }
  },
})
</script>

<style lang="scss" scoped>
.placeload {
  display: flex;
  align-items: center;
  width: v-bind('$props.width');
  height: v-bind('targetHeight');
}

.content-shape {
  height: v-bind('shapeHeight');

  &::before,
  &::after {
    content: ' ';
    display: table;
  }
}
</style>
