<template>
  <div class="page">
    <slot />
  </div>
</template>

<style lang="scss">
.page {
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-rows: min-content 1fr;
  width: calc(100% - 280px);
  margin-left: 280px;

  @media only screen and (width <= 768px) {
    width: 100% !important;
    margin-left: 0 !important ;
  }
}
</style>
