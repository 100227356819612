/// <reference types="vite/client" />

import { useStorage } from '@vueuse/core'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

import { useUserApi } from '@src/api/user.api'
import { useUserClientApi } from '@src/api/userClient.api'

import { useWaitFn } from '@src/hooks/useWaitFn.hook'

import {
  type UpdateUser,
  type User,
  type UserId,
  type UserPermission,
  type UserSettingsEnum,
} from '@src/types/api/user.type'
import { type UserClient } from '@src/types/api/userClient.type'

export const useUserStore = defineStore('user', () => {
  const grantorId = useStorage<UserId | null>('grantor-id', null)

  const userApi = useUserApi()
  const userClientApi = useUserClientApi()

  const user = ref<User>()
  const userClients = ref<UserClient[]>([])
  const permissions = ref(new Set<UserPermission>())
  const permissionsFetched = ref(false)

  const fetchUser = useWaitFn(async () => {
    if (user.value) {
      return user.value
    }

    const { data } = await userApi.fetchUser()

    user.value = data

    return data
  })

  function getSetting(setting: UserSettingsEnum) {
    if (!user.value) {
      return false
    }

    if (!user.value.attributes.settings) {
      return false
    }

    return user.value.attributes.settings[setting] ?? false
  }

  const fetchUserClients = useWaitFn(async () => {
    if (userClients.value.length) {
      return userClients.value
    }

    const { data } = await userClientApi.fetch()

    userClients.value = data

    return data
  })

  async function updateUser(data: UpdateUser) {
    const { data: updatedUser } = await userApi.updateUser(data)

    user.value = updatedUser

    return updatedUser
  }

  async function updateAvatar(file: Blob) {
    const { data: updatedUser } = await userApi.updateAvatar(file)

    user.value = updatedUser

    return updatedUser
  }

  const fetchPermissions = useWaitFn(async () => {
    const { data: fetchedPermissions } = await userApi.fetchPermissions()

    permissions.value = new Set(fetchedPermissions)
    permissionsFetched.value = true
  })

  return {
    grantorId,
    permissions,
    permissionsFetched,
    user,
    userClients,
    fetchPermissions,
    fetchUser,
    fetchUserClients,
    updateUser,
    updateAvatar,
    getSetting,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
