import { createGlobalState } from '@vueuse/core'
import { Centrifuge } from 'centrifuge'

import { useSocketApi } from '@src/api/socket.api'

export const useSocket = createGlobalState(() => {
  const socketApi = useSocketApi()

  return new Centrifuge(import.meta.env.VITE_WEBSOCKET_ENDPOINT, {
    getToken: async () => {
      const response = await socketApi.fetchConnectionToken()

      return response.data
    },
  })
})
