import { type AxiosError } from 'axios'
import * as _ from 'lodash-es'

export function keysToCamelCase(data: any): any {
  if (_.isArray(data)) {
    return data.map((item) => keysToCamelCase(item))
  } else if (_.isPlainObject(data)) {
    const pairs = _.toPairs(data).map(([key, value]) => {
      return [_.camelCase(key), keysToCamelCase(value)]
    })

    return _.fromPairs(pairs)
  }

  return data
}

export function keysToSnakeCase(data: any): any {
  if (_.isArray(data)) {
    return data.map((item) => keysToSnakeCase(item))
  } else if (_.isPlainObject(data)) {
    const pairs = _.toPairs(data).map(([key, value]) => {
      return [_.snakeCase(key), keysToSnakeCase(value)]
    })

    return _.fromPairs(pairs)
  }

  return data
}

export function getMessageMarkup(error: AxiosError<any, any>) {
  const { message, errors } = error.response?.data || {}

  if (!message) {
    return ''
  }

  const messageMarkup = `<div class="title">${message}</div>`

  let parsedErrors: string[] = []

  if (_.isArray(errors)) {
    parsedErrors = errors
  } else if (_.isPlainObject(errors)) {
    parsedErrors = _.values(errors).flat()
  }

  const errorsMarkup = parsedErrors.length
    ? `<div class="mt-3">${parsedErrors.map((error) => `<div>● ${error}</div>`).join('')}</div>`
    : ''

  return `${messageMarkup}${errorsMarkup}`
}

export function downloadUrl(url: string, filename?: string) {
  const link = document.createElement('a')

  link.href = url
  link.target = '_blank'

  if (filename) {
    link.setAttribute('download', filename)
  }

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function downloadBlob(response: Blob, filename: string) {
  const blob = new Blob([response], { type: response.type })
  const url = window.URL.createObjectURL(blob)

  downloadUrl(url, filename)

  window.URL.revokeObjectURL(url)
}
