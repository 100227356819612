import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { RouteName } from '@src/types/router.type'

export const bonusesRoute = {
  name: RouteName.Bonuses,
  path: '/bonuses',
  component: () => import('@src/views/bonuses/Bonuses.vue'),
  meta: { canView: ({ permissions }) => permissions.has('app.bonuses.view') },
} satisfies DeepReadonly<RouteRecordRaw>
