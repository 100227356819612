import { definePlugin } from '@src/bootstrap/app'
import { storeToRefs } from 'pinia'

import { useUserStore } from '@src/stores/user.store'

import { useOauth } from '@src/hooks/useOauth.hook'

import { RouteName } from '@src/types/router.type'

export default definePlugin(({ router }) => {
  router.beforeEach(async (to, from, next) => {
    const oauth = useOauth()
    const userStore = useUserStore()

    if (oauth.isAuthorized && !userStore.permissionsFetched) {
      await userStore.fetchPermissions().catch(() => {})
    }

    const { permissions } = storeToRefs(userStore)

    const cannotRouteView = to.matched.some((route) => {
      return route.meta.canView ? !route.meta.canView({ params: to.params, permissions: permissions.value }) : false
    })

    if (cannotRouteView) {
      return next({ name: RouteName.NotFound, params: { path: to.path.slice(1).split('/') } })
    }

    next()
  })
})
