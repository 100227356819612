import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { RouteName } from '@src/types/router.type'

export const cabinetsRoute = {
  name: RouteName.Cabinets,
  path: '/cabinets',
  component: () => import('@src/views/cabinets/Cabinets.vue'),
  redirect: { name: RouteName.NotFound, params: { path: ['cabinets'] } },
  children: [
    {
      name: RouteName.CabinetView,
      path: ':cabinetId(\\d+)',
      component: () => import('@src/views/cabinets/views/view/CabinetView.vue'),
    },
    {
      name: RouteName.CabinetCreate,
      path: 'create/:advertiserId(\\d+)',
      component: () => import('@src/views/cabinets/views/create/CabinetCreate.vue'),
      meta: {
        canView: ({ params, permissions }) => permissions.has(`app.customer.*.${Number(params.advertiserId)}`),
      },
    },
    {
      name: RouteName.CabinetRefill,
      path: ':cabinetId(\\d+)/refill',
      component: () => import('@src/views/cabinets/views/refill/CabinetRefill.vue'),
      meta: {
        canView: ({ params, permissions }) => permissions.has(`app.cabinet.*.${Number(params.cabinetId)}`),
      },
    },
    {
      name: RouteName.CabinetRefund,
      path: ':cabinetId(\\d+)/refund',
      component: () => import('@src/views/cabinets/views/refund/CabinetRefund.vue'),
      meta: {
        canView: ({ params, permissions }) => permissions.has(`app.cabinet.*.${Number(params.cabinetId)}`),
      },
    },
  ],
} satisfies DeepReadonly<RouteRecordRaw>
