import * as _ from 'lodash-es'
import { type AsyncComponentLoader, type Component, type ComponentProps, defineAsyncComponent, markRaw } from 'vue'

import { usePopups } from '@src/hooks/usePopups.hook'

import { type MaybeFactory } from '@src/types/common.type'

export function usePopup<T extends Component>(loader: AsyncComponentLoader<T>) {
  type Props = {} extends ComponentProps<T> ? ComponentProps<T> | void : ComponentProps<T>

  const { open } = usePopups()

  function show(factory: (resolve: () => void, reject: () => void) => Props): Promise<void>
  function show(props: Props): void
  function show(props: MaybeFactory<(resolve: () => void, reject: () => void) => Props>) {
    const key = _.uniqueId('popup-')
    const component = markRaw(defineAsyncComponent({ loader, delay: 0 }))

    if (_.isFunction(props)) {
      return new Promise<void>((resolve, reject) => {
        open(key, { component, props: props(resolve, reject) || {} })
      })
    }

    open(key, { component, props: props || {} })
  }

  return show
}
