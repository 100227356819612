<template>
  <div>
    <suspense>
      <router-view v-slot="{ Component }">
        <transition mode="out-in" name="fade-slow">
          <component :is="isAuthorized ? Component : Loading" />
        </transition>
      </router-view>
    </suspense>

    <component
      :is="popup.component"
      v-for="(popup, key) in popups"
      v-bind="popup.props"
      :key="key"
      @close="close(key)"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import VButton from '@src/components/base/button/VButton.vue'
import VCard from '@src/components/base/card/VCard.vue'
import VCardContent from '@src/components/base/card/VCardContent.vue'
import VCardHeader from '@src/components/base/card/VCardHeader.vue'
import VPage from '@src/components/base/page/VPage.vue'
import VPageContent from '@src/components/base/page/VPageContent.vue'
import VPageHeader from '@src/components/base/page/VPageHeader.vue'
import Loading from '@src/views/loading/Loading.vue'

import { initDarkmode } from '@src/stores/darkmode.store'
import { initSocketEvents } from '@src/stores/socket.store'

import { useOauth } from '@src/hooks/useOauth.hook'
import { usePopups } from '@src/hooks/usePopups.hook'

export default defineComponent({
  components: { VPage, VPageContent, VPageHeader, VCardContent, VCard, VCardHeader, VButton },

  setup() {
    const oauth = useOauth()

    const { popups, close } = usePopups()

    const isAuthorized = computed(() => oauth.isAuthorized)

    initDarkmode()
    initSocketEvents()

    return { Loading, isAuthorized, popups, close }
  },
})
</script>
