<template>
  <component :is="componentName" :class="classes" :to="to">
    <slot />
  </component>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

import VIcon from '@src/components/base/icon/VIcon.vue'

import { useVModel } from '@src/hooks/useVModel.hook'

export type VCardRadius = 'regular' | 'smooth' | 'rounded'

export default defineComponent({
  components: {
    VIcon,
  },

  props: {
    to: { type: <PropType<RouteLocationRaw>>[Object, String], default: undefined },
    radius: { type: <PropType<VCardRadius>>String, default: undefined },
    accent: { type: Boolean, default: false },
    danger: { type: Boolean, default: false },

    open: { type: <PropType<boolean | null>>Boolean, default: null },
    outlined: { type: Boolean, default: false },
  },

  emits: {
    'update:open': (_open: boolean) => true,
  },

  setup(props, { emit }) {
    const syncedOpen = useVModel(props, 'open', emit)

    const componentName = computed(() => (props.to ? 'router-link' : 'div'))

    const classes = computed(() => {
      return [
        'card',
        props.radius && `is-${props.radius}`,
        props.accent && 'is-accent',
        props.danger && 'is-danger',
        props.open && 'is-open',
        props.outlined && 'is-outlined',
      ]
    })

    const toggle = () => (syncedOpen.value = !syncedOpen.value)

    return { classes, componentName, toggle }
  },
})
</script>
