import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { RouteName } from '@src/types/router.type'

export const pointsRoute = {
  name: RouteName.Points,
  path: '/points',
  component: () => import('@src/views/points/Points.vue'),
  children: [
    {
      name: RouteName.PointsShop,
      path: '',
      component: () => import('@src/views/points/views/shop/PointsShop.vue'),
    },
    {
      name: RouteName.PointsHistory,
      path: 'history',
      component: () => import('@src/views/points/views/history/PointsHistory.vue'),
    },

    {
      name: RouteName.PointsBuy,
      path: 'buy/:pointsServiceId(\\d+)',
      component: () => import('@src/views/points/views/buy/PointsBuy.vue'),
    },
  ],
} satisfies DeepReadonly<RouteRecordRaw>
