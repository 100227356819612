import { createHead } from '@unhead/vue'
import { createPinia } from 'pinia'
import PortalVue from 'portal-vue'
import { createApp as createClientApp } from 'vue'

import VueroApp from '@src/VueroApp.vue'

import { createApi } from '@src/hooks/useApi.hook'
import { createEmitter } from '@src/hooks/useEmitter.hook'
import { createOauth } from '@src/hooks/useOauth.hook'

import { createRouter } from './router'

import './styles'

export type VueroAppContext = Awaited<ReturnType<typeof createApp>>
export type VueroPlugin = (vuero: VueroAppContext) => void | Promise<void>

const plugins = import.meta.glob<{ default: VueroPlugin }>('./plugins/*.ts', {
  eager: true,
})

// this is a helper function to define plugins with autocompletion
export function definePlugin(plugin: VueroPlugin) {
  return plugin
}

export async function createApp() {
  const app = createClientApp(VueroApp)
  const pinia = createPinia()
  const router = createRouter()
  const oauth = createOauth(router)
  const api = createApi(oauth, pinia)
  const head = createHead()
  const emitter = createEmitter()

  app.use(head)
  app.use(pinia)
  app.use(PortalVue)

  const vuero = { app, api, router, head, pinia, oauth, emitter }

  app.provide('vuero', vuero)

  for (const path in plugins) {
    try {
      const { default: plugin } = plugins[path]

      await plugin(vuero)
    } catch (error) {
      console.error(`Произошла ошибка во время загрузки плагина "${path}".`)
      console.error(error)
    }
  }

  // use router after plugin registration, so we can register navigation guards
  app.use(vuero.router)

  return vuero
}
