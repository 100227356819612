import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { useOauth } from '@src/hooks/useOauth.hook'

import { RouteName } from '@src/types/router.type'

export const authRoute = {
  name: RouteName.OauthCallback,
  path: import.meta.env.VITE_OAUTH_REDIRECT_PATH,
  component: () => import('@src/views/loading/Loading.vue'),
  beforeEnter: async () => {
    const oauth = useOauth()

    try {
      await oauth.receiveCode()
      await oauth.getTokens()
      await oauth.return()
    } catch (e) {
      console.warn('AUTH ROUTE ERROR', e)
    }
  },
} satisfies DeepReadonly<RouteRecordRaw>
