/// <reference types="vite/client" />

import axios from 'axios'
import { type PublicationContext, type Subscription, type SubscriptionEvents, UnauthorizedError } from 'centrifuge'
import { type EventMap, type SubscriptionOptions } from 'centrifuge/build/types'
import * as _ from 'lodash-es'
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia'
import { computed, onUnmounted, ref, watch } from 'vue'

import { useSocketApi } from '@src/api/socket.api'

import { useUserStore } from '@src/stores/user.store'

import { useEmitter } from '@src/hooks/useEmitter.hook'
import { useOauth } from '@src/hooks/useOauth.hook'
import { useSocket } from '@src/hooks/useSocket.hook'

import { keysToCamelCase } from '@src/utils/api.util'

export function initSocketEvents() {
  const emitter = useEmitter()
  const socketStore = useSocketStore()
  const { user } = storeToRefs(useUserStore())
  const eventsChannelName = computed(() => (user.value?.id ? `private:events#${user.value?.id}` : null))

  function socketOnEventClientListHandler(ctx: PublicationContext) {
    emitter.emit(ctx.data.event, {
      event: ctx.data,
    })
  }

  function initEventSubscription() {
    if (!eventsChannelName.value) {
      return
    }

    socketStore.getSubscription(eventsChannelName.value).on('publication', socketOnEventClientListHandler)
  }

  watch(user, (val) => {
    if (val) {
      initEventSubscription()
    }
  })

  onUnmounted(() =>
    eventsChannelName.value && socketStore.hasSubscription(eventsChannelName.value)
      ? socketStore.getSubscription(eventsChannelName.value).off('publication', socketOnEventClientListHandler)
      : null
  )

  initEventSubscription()
}

export const useSocketStore = defineStore('socket', () => {
  const socket = useSocket()
  const socketApi = useSocketApi()
  const oauth = useOauth()
  const subscriptions = ref<Record<string, Subscription>>({})

  function getSubscription(channel: string): Subscription {
    if (subscriptions.value[channel]) {
      return subscriptions.value[channel]
    }

    const options: Partial<SubscriptionOptions> = {
      getToken: async (ctx) => {
        try {
          const response = await socketApi.fetchSubscriptionToken(ctx.channel)

          return response.data.token
        } catch (e) {
          if (axios.isAxiosError(e) && e.response?.status === axios.HttpStatusCode.Forbidden) {
            // Обязательно выбрасывать это исключение при 403, иначе центрифуга будет бесконечно пытаться подключиться.
            throw new UnauthorizedError('Unauthorized to connect this channel')
          }

          throw e
        }
      },
    }

    const subscription = socket.newSubscription(channel, options)

    // переопределяем родную функцию на свою, чтоб воткнуть мидлвар для данных - надо ключи в snakeCase перегонять в ресурсе
    const originalOn = subscription.on

    subscription.on = function <E extends keyof EventMap>(event: E, listener: EventMap[E]): typeof subscription {
      const wrappedListener = (...args: Parameters<EventMap[E]>) => {
        // перегоняем ключи в ресурсе в camelCase
        if (event === 'publication' && args[0].data.resource) {
          _.set(args, '[0].data.resource', keysToCamelCase(args[0].data.resource))
        } else if (event === 'publication' && args[0].data.attributes) {
          _.set(args, '[0].data.attributes', keysToCamelCase(args[0].data.attributes))
        }

        return listener(...args)
      }

      originalOn.call(this, event as keyof SubscriptionEvents, wrappedListener as EventMap[E])

      return subscription
    }

    subscription.subscribe()

    subscriptions.value[channel] = subscription

    return subscription
  }

  function hasSubscription(channel: string): boolean {
    return !!subscriptions.value[channel]
  }

  function deleteSubscription(channel: string) {
    if (subscriptions.value[channel]) {
      subscriptions.value[channel].unsubscribe()
      delete subscriptions.value[channel]
    }
  }

  function clearAllSubscriptions() {
    Object.keys(subscriptions.value).forEach((channel) => {
      deleteSubscription(channel)
    })
  }

  watch(
    () => oauth.isAuthorized,
    (isAuthorized) => {
      if (isAuthorized) {
        socket.connect()
      } else {
        clearAllSubscriptions()
        socket.disconnect()
      }
    },
    { immediate: true }
  )

  return {
    getSubscription,
    hasSubscription,
    deleteSubscription,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSocketStore, import.meta.hot))
}
