/// <reference types="vite/client" />

import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

import { useSettingsApi } from '@src/api/settings.api'

import { useWaitFn } from '@src/hooks/useWaitFn.hook'

import { type Settings } from '@src/types/api/settings.type'

export const useSettingsStore = defineStore('settings', () => {
  const settingsApi = useSettingsApi()

  const settings = ref<Settings>()

  const fetchSettings = useWaitFn(async () => {
    if (settings.value) {
      return settings.value
    }

    const { data } = await settingsApi.fetchSettings()

    settings.value = data

    return data
  })

  return {
    settings,
    fetchSettings,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSettingsStore, import.meta.hot))
}
