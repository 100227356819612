<template>
  <div class="page-content">
    <div class="page-content-inner">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
:root {
  --page-content-padding-x: 40px;
  --page-content-padding-y: 20px;
}

.page-content {
  width: 100%;
  overflow-y: auto;
}

.page-content-inner {
  max-width: 1118px;
  margin: 0 auto;
  padding: var(--page-content-padding-y) var(--page-content-padding-x);
}
</style>
