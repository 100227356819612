<template>
  <div class="card-head">
    <slot>
      <div v-if="$slots.left" class="left">
        <slot name="left" />
      </div>

      <div v-if="$slots.right" class="right">
        <slot name="right" />
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>
