import { type AxiosRequestConfig } from 'axios'

import { useApi } from '@src/hooks/useApi.hook'

import {
  type BaseResponse,
  type GetIncludes,
  type IncludeParams,
  type Model,
  type ModelId,
  type PaginationParams,
  type WithIncludes,
} from '@src/types/api/common.type'
import { type GetMutable, type Merge } from '@src/types/common.type'

export function useCrudApi<
  M extends Model,
  C = GetMutable<Model['attributes']>,
  U = Partial<C>,
  P extends object = PaginationParams,
>(url: string, defaults: Omit<AxiosRequestConfig, 'params'> = {}) {
  const api = useApi()

  return {
    fetch<const T extends GetIncludes<M> = []>(
      params?: Merge<P, IncludeParams<T>>,
      config?: Omit<AxiosRequestConfig, 'params'>
    ) {
      return api.get<any, BaseResponse<WithIncludes<M, T>[], true>>(url, { ...defaults, ...config, params })
    },

    show<const T extends GetIncludes<M> = []>(id: ModelId<M>, params?: IncludeParams<T>) {
      return api.get<any, BaseResponse<WithIncludes<M, T>>>(`${url}/${id}`, { ...defaults, params })
    },

    create<const T extends GetIncludes<M> = []>(data: C, params?: IncludeParams<T>) {
      return api.post<any, BaseResponse<WithIncludes<M, T>>>(url, data, { ...defaults, params })
    },

    update<const T extends GetIncludes<M> = []>(id: ModelId<M>, data: U, params?: IncludeParams<T>) {
      return api.patch<any, BaseResponse<WithIncludes<M, T>>>(`${url}/${id}`, data, { ...defaults, params })
    },

    destroy(id: ModelId<M>) {
      return api.delete<any, BaseResponse<M>>(`${url}/${id}`)
    },
  }
}
