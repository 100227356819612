import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { RouteName } from '@src/types/router.type'

export const advertisersRoute = {
  name: RouteName.Advertisers,
  path: '/advertisers',
  component: () => import('@src/views/advertisers/Advertisers.vue'),
  redirect: { name: RouteName.NotFound, params: { path: ['advertisers'] } },
  children: [
    {
      name: RouteName.AdvertiserView,
      path: ':advertiserId(\\d+)',
      component: () => import('@src/views/advertisers/views/view/AdvertiserView.vue'),
    },
    {
      name: RouteName.AdvertiserCreate,
      path: 'create/:clientId(\\d+)',
      component: () => import('@src/views/advertisers/views/save/AdvertiserSave.vue'),
      meta: {
        canView: ({ params, permissions }) =>
          permissions.has(`app.client.*.${Number(params.clientId)}`) ||
          permissions.has(`app.client.edit.${Number(params.clientId)}`),
      },
    },
    {
      name: RouteName.AdvertiserUpdate,
      path: ':advertiserId(\\d+)/update',
      component: () => import('@src/views/advertisers/views/save/AdvertiserSave.vue'),
    },
  ],
} satisfies DeepReadonly<RouteRecordRaw>
