import { definePlugin } from '@src/bootstrap/app'
import dayjs from 'dayjs'
import { useRegisterSW } from 'virtual:pwa-register/vue'

import { usePopup } from '@src/hooks/usePopup.hook'
import { useProgress } from '@src/hooks/useProgress.hook'

export default definePlugin(() => {
  const uploading = useProgress()

  const showNotification = usePopup(() => import('@src/components/base/notification/VNotification.vue'))

  useRegisterSW({
    immediate: true,
    onRegisteredSW(swScriptUrl, registration) {
      if (!registration) {
        return
      }

      registration.addEventListener('updatefound', () => {
        uploading.value = true

        showNotification({
          message: 'Началась загрузка новой версии приложения. Пожалуйста, дождитесь окончания загрузки.',
          color: 'info',
          duration: null,
        })
      })

      setInterval(() => registration.update(), dayjs.duration(1, 'minutes').asMilliseconds())
    },
  })
})
